import { BBX_APPLICATION } from '../types/nextJS'

const FeatureToggles = {
    jobs: '/jobs/nodeapi/toggles',
    search: '/nodeapi/toggles',
    adin: '/bbx-adin/nodeapi/toggles',
    lead: '/bbx-lead/nodeapi/toggles',
}

export const getFeatureToggles = async (app: BBX_APPLICATION): Promise<Record<string, boolean>> =>
    await fetch(FeatureToggles[app], {
        headers: { Accept: 'application/json' },
    })
        .then((response) => response.json() as Promise<Record<string, boolean>>)
        .catch(() => {
            // error on fetching feature toggles in the browser: empty object should default all toggles to off
            return {}
        })
