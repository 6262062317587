import { StoryblokDefaultProps } from '../../types/StoryblokComponents'
import { storyblokEditable } from '@storyblok/js'
import { SbBlokData, StoryblokComponentType } from '@storyblok/js/dist/types/types'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { isValidUrl } from '@wh/common/chapter/lib/urlHelpers'
import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

interface StoryblokIframe extends StoryblokComponentType<string> {
    url: string
    height: string
}

interface Props extends StoryblokDefaultProps<StoryblokIframe> {}

const allowedDomains = [
    'youtube-nocookie.com',
    'youtube.com',
    'app.23degrees.io',
    'test-wh-taas.2trde.com',
    'test-taas.2trde.com',
    'taas.2trde.com',
    'willhaben.poweredby.shadowmap.org',
]

export const StoryblokIframe: FunctionComponent<Props> = ({ blok }) => {
    let url = blok.url

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`
    }

    if (!isValidUrl(url)) {
        return (
            <Text color="palette.signal.red" fontWeight="bold" {...storyblokEditable(blok as unknown as SbBlokData)}>
                URL ist ungültig
            </Text>
        )
    }

    const urlStartingWithDomain = url.substring(url.indexOf('//') + 2)
    if (!allowedDomains.find((domain) => urlStartingWithDomain.startsWith(domain) || urlStartingWithDomain.startsWith(`www.${domain}`))) {
        return (
            <Text color="palette.signal.red" fontWeight="bold" {...storyblokEditable(blok as unknown as SbBlokData)}>
                Keine gültige Domain angegeben.
                <br />
                Zum Erlauben der Domain beim Search-Tribe melden.
            </Text>
        )
    }

    const isHeightAspectRatio = blok.height.includes('/')

    const pixelHeight = `${blok.height}${blok.height.endsWith('px') ? '' : 'px'}`

    return (
        <Box
            height={!isHeightAspectRatio ? pixelHeight : undefined}
            css={css`
                aspect-ratio: ${isHeightAspectRatio ? blok.height : undefined};
            `}
            {...storyblokEditable(blok as unknown as SbBlokData)}
        >
            <IFrame src={url} frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture" allowFullScreen={true} />
        </Box>
    )
}

const IFrame = styled.iframe`
    display: block;
    width: 100%;
    height: 100%;
`
